/* anton-400normal - latin */
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Anton Regular '),
    local('Anton-Regular'),
    url(/static/media/anton-latin-400.0d2747a0.woff2) format('woff2'), 
    url(/static/media/anton-latin-400.8be636df.woff) format('woff'); /* Modern Browsers */
}


